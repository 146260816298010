import { DataLayerClient, IDataLayerClient } from '@studyportals/datalayer';

export class DataLayerEventSender {
	private dataLayerClient: IDataLayerClient;

	constructor() {
		this.dataLayerClient = new DataLayerClient();

		this.bootDataLayer();
	}

	private bootDataLayer(): void {
		this.dataLayerClient.initialiseDataLayer();
	}
}

new DataLayerEventSender();
